export const ReferralOutType = {
  POLARIS: 'Polaris',
  NON_POLARIS: 'Non-Polaris'
}

export const ReferralOutTypeOptions = [
  ReferralOutType.POLARIS,
  ReferralOutType.NON_POLARIS
]

export const ReferralOutTreatmentTypes = {
  MRI: 'MRI',
  CT: 'CT',
  X_RAY: 'X-Ray',
  ORTHO: 'Ortho',
  NEURO: 'Neuro',
  PROCEDURE: 'Procedure'
}

export const ReferralOutTreatmentOptions = [
  ReferralOutTreatmentTypes.MRI,
  ReferralOutTreatmentTypes.CT,
  ReferralOutTreatmentTypes.X_RAY,
  ReferralOutTreatmentTypes.ORTHO,
  ReferralOutTreatmentTypes.NEURO,
  ReferralOutTreatmentTypes.PROCEDURE
]

export const ReferralOutStatuses = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  SCHEDULED: 'Scheduled',
  COMPLETED: 'Completed',
  DENIED: 'Denied'
}

export const ReferralOutStatusOptions = [
  ReferralOutStatuses.PENDING,
  ReferralOutStatuses.APPROVED,
  ReferralOutStatuses.SCHEDULED,
  ReferralOutStatuses.COMPLETED,
  ReferralOutStatuses.DENIED
]
