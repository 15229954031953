<template>
  <v-form ref="form" v-model="formValid">
    <v-row dense>
      <v-col>
        <v-select
          v-model="referral.type"
          :items="ReferralOutTypeOptions"
          label="Referral Type"
          item-text="text"
          item-value="value"
          filled dense
          :rules="[rules.required]"
          @change="showCreateServiceLocation = false"
        />
      </v-col>
      <v-col v-if="referral.hasOwnProperty('status')">
        <v-select
          v-model="referral.status"
          :items="ReferralOutStatusOptions"
          label="Status"
          item-text="text"
          item-value="value"
          filled dense
          :rules="[rules.required]"
        />
      </v-col>
    </v-row>
    <div>
      <v-row dense>
        <v-col>
          <v-select
            v-model="referral.treatment"
            :items="ReferralOutTreatmentOptions"
            label="Treatment"
            filled dense
            :rules="[rules.required]"
            clearable
          />
        </v-col>
        <v-col>
          <v-text-field
            v-model="referral.body_part"
            label="Body Part"
            filled
            dense
            :rules="[rules.required]"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="6">
          <date-picker
            ref="datepicker"
            :date="referral.date_sent"
            :default-to-today="false"
            label="Date Sent"
            @date-change="setActionDate"
            :dateValid="dateValid"
            :rules="[rules.required]"
          />
        </v-col>
        <v-col v-if="referral.type === ''">
          <v-text-field
            v-if="referral.type === ''"
            :disabled="true"
            label="Service Location"
            filled dense
          />
        </v-col>
        <v-col v-if="referral.type === ReferralOutType.POLARIS" class="d-flex">
          <v-select
            v-model="referral.service_location_id"
            v-if="referral.type === ReferralOutType.POLARIS"
            :items="serviceLocations"
            item-text="name"
            item-value="id"
            label="Service Location"
            filled dense
            clearable
            :rules="[rules.required]"
            @change="clearServiceLocationOther"
          />
          <v-icon
            class="ml-2 mb-5"
            style="cursor: pointer; user-select: none;"
            color="success"
            @click="showCreateServiceLocation = true"
          >
            fa-plus
          </v-icon>
        </v-col>
        <v-col v-if="referral.type === ReferralOutType.NON_POLARIS">
          <v-combobox
            v-model="referral.service_location_other"
            :items="['Open']"
            v-if="referral.type === ReferralOutType.NON_POLARIS"
            label="Service Location Other"
            filled dense
            :rules="[rules.required]"
            @change="clearServiceLocationID"
          />
        </v-col>
      </v-row>
      <v-row dense v-if="showCreateServiceLocation" class="my-3">
        <v-col>
          <div class="font-weight-bold mb-1">Create Service Location</div>
          <LocationForm ref="locationForm" @cancel="showCreateServiceLocation = false" @save="createServiceLocation"/>
        </v-col>
      </v-row>
      <div class="font-weight-bold mb-1">Referral Appointment</div>
      <v-row dense>
        <v-col cols="5">
          <date-picker
            ref="appointmentDatepicker"
            :date="referral.appointment_date"
            :default-to-today="false"
            label="Appointment Date"
            @date-change="setAppointmentDate"
            :dateValid="apptDateValid"
            :rules="[appointmentRules]"
          />
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-model="referral.time"
            label="Time"
            v-mask="'##:##'"
            hint="format hh:mm"
            filled prepend-inner-icon="fa-clock"
            dense
            :rules="[appointmentRules]"
          />
        </v-col>
        <v-col>
          <v-select
            :items="['AM', 'PM']"
            v-model="referral.day"
            filled
            dense
            label="AM/PM"
            :rules="[appointmentRules]"
          />
        </v-col>
      </v-row>
      <div>
        <v-row>
          <v-col>
            <div class="font-weight-bold">Firm Recipients</div>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn small color="primary" @click="addRecipient">Add Recipient</v-btn>
          </v-col>
        </v-row>
        <div v-if="referral.hasOwnProperty('firm_recipients') && referral.firm_recipients.length === 0">No Firm Recipients</div>
        <v-row v-else>
          <v-col cols="6" class="d-flex" v-for="(recipient, index) in referral.firm_recipients" :key="index">
            <FirmMemberAutoComplete
              ref="firmMemberAutoComplete"
              :value="referral.firm_recipients[index]"
              @input="handleFirmMembersSelection(index, $event)"
              clearable dense
            />
            <v-icon class="text-h4 red--text mb-6 ml-1" @click="deleteRecipient(index)">mdi-alpha-x-box</v-icon>
          </v-col>
        </v-row>
      </div>
      <div class="mt-8">
        <v-row class="mb-1">
          <v-col>
            <div class="font-weight-bold">Referral Note</div>
          </v-col>
          <v-col class="d-flex justify-end">
            <v-btn v-if="referral.id" small color="primary" @click="addNote" :loading="noteLoading">Add Note</v-btn>
          </v-col>
        </v-row>
        <v-textarea v-model="referral.note" filled placeholder="Referral note...." :rules="[rules.optional]"/>
      </div>
    </div>
  </v-form>
</template>
<script>

import RulesMixin from '@/mixins/RulesMixin'
import DatePicker from '@/components/PatientIntake/DatePicker.vue'
import { ReferralOutTypeOptions, ReferralOutType, ReferralOutTreatmentOptions, ReferralOutStatusOptions } from '@/js/case/ReferralOut'
import { mapState } from 'vuex'
import FirmMemberAutoComplete from '@/components/FormFields/FirmMemberAutoComplete.vue'
import structuredClone from '@ungap/structured-clone'
import LocationForm from '@/components/Locations/LocationForm'
import event, { Events } from '@/event'
import { LocationTypes } from '@/js/LocationTypes'

export default {
  name: 'ReferralOutForm',
  mixins: [RulesMixin],
  components: { DatePicker, FirmMemberAutoComplete, LocationForm },
  props: {
    passedReferral: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    formValid: true,
    dateValid: true,
    apptDateValid: true,
    noteLoading: false,
    showCreateServiceLocation: false,
    referral: {},
    ReferralOutType,
    ReferralOutTypeOptions,
    ReferralOutTreatmentOptions,
    ReferralOutStatusOptions,
    LocationTypes
  }),
  computed: {
    ...mapState({
      serviceLocations: state => state.Organization.serviceLocations
    }),
    appointmentRules () {
      if (this.referral.appointment_date || this.referral.time || this.referral.day) {
        return this.rules.required
      }
      return this.rules.optional
    }
  },
  created () {
    this.referral = structuredClone(this.passedReferral)
  },
  mounted () {
    this.referral = structuredClone(this.passedReferral)
    this.$nextTick(() => {
      this.$refs.datepicker.checkDate()
      this.$refs.appointmentDatepicker.checkDate()
    })
    this.$store.dispatch('Organization/getServiceLocations')
  },
  methods: {
    setActionDate (value) {
      this.referral.date_sent = value
    },
    setAppointmentDate (value) {
      this.referral.appointment_date = value
    },
    addNote () {
      this.noteLoading = true
      this.$emit('addNote', this.referral.note)
    },
    validateDate () {
      this.dateValid = true
      if (!this.referral.date_sent) {
        this.dateValid = 'Required'
      }
      this.apptDateValid = true
      if (!this.referral.appointment_date && (this.referral.time || this.referral.day)) {
        this.apptDateValid = 'Required'
      }
    },
    validate () {
      this.$refs.form.validate()
      this.validateDate()
    },
    reset () {
      this.dateValid = true
      this.apptDateValid = true
      this.$refs.form.reset()
      this.referral = {}
    },
    addRecipient () {
      if (this.referral.firm_recipients.length === 0) {
        this.referral.firm_recipients = ['']
      } else {
        this.referral.firm_recipients.push('')
      }
    },
    deleteRecipient (index) {
      this.referral.firm_recipients.splice(index, 1)
    },
    clearServiceLocationOther () {
      this.referral.service_location_other = ''
    },
    clearServiceLocationID () {
      this.referral.service_location_id = null
    },
    handleFirmMembersSelection (index, value) {
      if (!value) {
        this.referral.firm_recipients[index] = ''
        return
      }
      if (typeof value === 'string') {
        this.referral.firm_recipients[index] = value
      } else if (typeof value === 'object') {
        this.referral.firm_recipients[index] = value.member_name
      }
    },
    async createServiceLocation (newLocation) {
      const currentLocation = newLocation
      currentLocation.location_type = LocationTypes.SERVICE.value

      const url = this.$store.getters['Organization/apiUrl'] + '/locations'
      await window.axios.post(url, currentLocation)

      this.$store.commit('Organization/' + LocationTypes.SERVICE.api, [])
      event.emit(Events.SUCCESS, 'Service Location was successfully created')

      await this.$store.dispatch('Organization/getServiceLocations')
      this.showCreateServiceLocation = false
      this.$refs.locationForm.loading = false
    }
  },
  updated () {
    this.$emit('updated', this.referral)
  }
}
</script>
<style scoped>
</style>
