<template>
  <v-combobox
    :loading="loading"
    :value="value"
    :items="items"
    :search-input.sync="search"
    cache-items
    label="Firm Member"
    item-value="id"
    item-text="member_name"
    filled
    v-bind="$attrs"
    :rules="[rules.required]"
    @input="$emit('input', $event)"
  />
</template>
<script>
import { debounce } from 'lodash'
import RulesMixin from '@/mixins/RulesMixin'
import { mapGetters } from 'vuex'

export default {
  name: 'FirmMemberAutoComplete',
  mixins: [RulesMixin],
  props: {
    value: [String, Object],
    lawFirmId: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  data: () => ({
    loading: false,
    search: '',
    items: []
  }),
  computed: {
    ...mapGetters({
      url: 'Organization/apiUrl'
    })
  },
  mounted () {
    this.searchMatches()
  },
  methods: {
    async searchMatches () {
      // start the loading bar
      this.loading = true

      const url = this.url + '/case/' + this.$route.params.caseId + '/members?name=' + this.search
      // search for matching attorneys in the system
      const response = await window.axios.get(url)

      // stop the loading bar
      this.loading = false

      this.items = response.data.payload.members
    }
  },
  watch: {
    search: debounce(function (val) {
      val && val !== this.value && this.searchMatches()
    }, 500)
  }
}
</script>
